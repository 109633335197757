import React from "react"
import { Box } from "theme-ui"

export default ({ raveText, author, position, company }) => {
  return (
    <Box
      sx={{
        margin: "auto",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        maxWidth: ["100%", "50vw"],
        mb: [40, 85],
        p: 30,
        h4: {
          m: 0,
        },
        "img, .initials": {
          height: 40,
          width: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          color: "#fff",
          bg: "gray",
          mr: 10,
        },
      }}
    >
      <Box sx={{ fontSize: [15, 23], fontStyle: "italic" }}>{raveText}</Box>
      <Box sx={{ mt: 30, display: ["block", "flex"], flexWrap: "wrap" }}>
        <Box sx={{ flex: 1, display: ["none", "block"] }}></Box>
        <Box
          sx={{
            display: ["block", "flex"],
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "center",
            mr: [0, 50],
            mt: [0, 20],
            h4: {
              fontSize: [20, 24],
            },
            ".company": { fontSize: [17, 21], ml: 10 },
          }}
        >
          <h4>
            {author}
            {position && `, ${position}`}
          </h4>
          <Box
            className="company"
            sx={{ color: "primary", fontWeight: "bold", m: 0 }}
          >
            {company}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
