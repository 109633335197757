import React, { useRef, useEffect } from "react"
import { Box } from "theme-ui"
import gsap from "gsap"

const blocks = [
  { s: "#shape0", x: "70px", y: "-30px", d1: 8, d2: 4 },
  { s: "#shape1", x: "-50px", y: "-90px", d1: 8, d2: 10 },
  { s: "#shape2", x: "100px", y: "120px", d1: 25, d2: 15 },
  { s: "#shape3", x: "40px", y: "90px", d1: 10, d2: 8 },
  { s: "#shape4", x: "100px", y: "-25px", d1: 8, d2: 12 },
  { s: "#shape5", x: "0", y: "100px", d1: 6, d2: 10 },
  { s: "#shape6", x: "20px", y: "170px", d1: 8, d2: 14 },
  { s: "#shape7", x: "-30px", y: "-90px", d1: 10, d2: 12 },
  { s: "#shape8", x: "-100px", y: "0", d1: 15, d2: 20 },
]

const groups = [
  "#group-1",
  "#arrows1",
  "#group-2",
  "#group-3",
  "#arrows2",
  "#group-4",
]

const AnimateTwo = () => {
  const svgRef = useRef(null)

  useEffect(() => {
    blocks.forEach(({ s, x, y, d1, d2 }) => {
      let blockTimeline = gsap.timeline({ repeat: -1 })
      blockTimeline
        .to(s, { transform: `translate3d(0, 0, 0)` })
        .to(s, { transform: `translate3d(${x}, ${y}, 0)`, duration: d1 })
        .to(s, { transform: "translate3d(0, 0, 0)", duration: d2 })
    })

    let master = gsap.timeline()

    groups.forEach((s) => {
      let wordTimeline = gsap.timeline()
      wordTimeline.to(s, { opacity: 0 }).to(s, { opacity: 1, duration: 1 })
      if (s.startsWith("#group")) {
        const line = `${s} path`
        const text = `${s} text`
        wordTimeline
          .to(line, { opacity: 0 })
          .to(line, { opacity: 1, duration: 1 })
        wordTimeline
          .to(text, { opacity: 0 })
          .to(text, { opacity: 1, delay: -0.5 })
      }
      master.add(wordTimeline)
    })
  }, [svgRef])

  return (
    <Box
      ref={svgRef}
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1164 517.92"
      sx={{
        // width: ["150%", "100%"],
        ".gray-bg": { fill: "#dedede", opacity: 0.25 },
        ".blue-bg": { fill: "#56b9e9", opacity: 0.25 },
        ".line": {
          fill: "none",
          stroke: "#e61515",
          strokeDasharray: "1190.27 1191.69",
          strokeMiterlimit: "7.11",
          strokeWidth: "0.71px",
        },
        "circle.full": {
          fill: "#fff",
          stroke: "#e61515",
          strokeWidth: 1,
        },
        ".animate-group": {
          opacity: 0,
          "text, path": {
            opacity: 0,
          },
        },
        ".arrows": {
          opacity: 0,
        },
        ".outer": { opacity: 0.4 },
        ".inner": { opacity: 0.7 },
        ".label-text": {
          fontSize: "13px",
          fill: "#000333",
          fontFamily: "Manrope, Helvetica, sans-serif",
        },
        ".bold-text": {
          fontSize: "14px",
          fill: "#58a7dd",
          fontFamily: "Manrope, Helvetica, sans-serif",
          fontWeight: 700,
        },
        ".triangle": {
          fill: "#78bef9",
        },
        ".dim": { opacity: 0.6 },
        ".dimmer": { opacity: 0.3 },
        ".dimmest": { opacity: 0.1 },
      }}
    >
      <g id="background">
        <path
          id="gray-1"
          className="gray-bg"
          d="M1164 102.29c-7.67-6.84-14.66-16-16.8-18.65-10-12.37-20-26.6-37.16-31.39-12.14-3.4-25.58-1.07-36.86 4.22s-20.68 13.28-29.39 21.63c-20.79 19.9-38.33 42.25-54.17 65.46-14.94 21.89-26.45 46.31-43.57 66.86s-46.46 42.26-76.52 31.19c-33-12.14-43.11-49-82.14-53.26-13.59-1.47-28.2 2.18-37.61 11.16-11.42 10.9-13.39 27-16.82 41.69-2.49 10.72-6.14 21.4-12.79 30.55s-16.62 16.72-28.43 19.25c-25.08 5.3-55.24-10.49-79.09-16.4-26.71-6.62-53.93-9.25-81.24-3.86-33.79 6.66-66.45 20.44-95.87 36.6-13.05 7.16-9.87 22.58 6.48 23 8.31.22 15.93-3.8 23.18-7.48a389 389 0 0169.23-27.14c-8.48 4-16.44 9.82-20 17.84-4.68 10.64.33 21.75 12.86 24.86 7.25 1.8 14.92.92 22.4.32 34.44-2.71 69.66 1.23 101.85 12.62 28.57 10.12 54.27 25.85 82.11 37.51s59.65 19.23 89.19 11.68c33.29-8.52 59.12-38 58.78-69.19-.51-52.25-58.62-71.36-107.35-66.87 2.31-7.79 3.53-18 12.23-22.46 6.76-3.48 15.31-1.74 22.5.93 25.53 9.49 45.35 29.21 71.36 37.55 23.35 7.48 49.67 4.37 71.8-5 18.09-7.64 30.16-23 47.81-30.88 17.41-7.8 38.25-8.57 57.08-5.43 28.87 4.82 71.18 33.1 98.87 17a53.92 53.92 0 008.08-5.78V102.29z"
        />
        <path
          id="gray-2"
          data-name="gray-2"
          className="gray-bg"
          d="M1164 367.16c-1.55-3.11-2.16-6.27 0-9.71v-19.16a11.4 11.4 0 00-3.24-.22c-6.24.48-11.4 4.36-16.36 7.81-32.75 22.79-75.73 33.24-116.73 28.36-17.07-2-33.7-6.57-50.81-8.37s-35.46-.55-49.82 8c-2.62 1.57-5.23 3.57-6 6.33-1.46 5.15 4.74 8.06 9.39 6.48 50.32-17 86.3 15.36 133.05 28.18 28.55 7.83 73 12.9 97.5-6.61a6.41 6.41 0 001.37-1.47l1.66-.45v-39.17zm-102.56 20.93a8.88 8.88 0 00-8-.12c-.8-.18-1.6-.35-2.39-.54 3.77-.33 7.54-.77 11.28-1.29a8.52 8.52 0 00-.92 1.95z"
        />
        <path
          id="shape0"
          className="blue-bg"
          d="M488.69 328.45l-9.39 5.82c-2.58 1.59-4 4.81-2.15 7.38 1.65 2.31 5.42 3.64 8.18 1.93l1.86-1.15a6.81 6.81 0 00.76-.26 23.32 23.32 0 008-5.24 5.06 5.06 0 00.47-7.09 6.37 6.37 0 00-7.71-1.39z"
        />
        <path
          id="shape1"
          className="blue-bg"
          d="M685.35 430.72c-4.28-6.57-12.71-10.81-22.43-11.59-8.66-.71-18 2.61-22.52 9.3-5.91 8.71-1.88 21.12 7.34 27 12.68 8.13 36.19 6.27 40-9.64a18.88 18.88 0 00-2.38-15.1z"
        />
        <path
          id="shape3"
          className="blue-bg"
          d="M939.91 396.79h-6c-2.87 0-5.61 2.27-5.48 4.94a5.33 5.33 0 005.48 4.95h6c2.87 0 5.61-2.27 5.48-4.95a5.33 5.33 0 00-5.48-4.94z"
        />
        <path
          id="shape2"
          className="blue-bg"
          d="M613.38 195.1c-11.72 0-22.94 5.51-22.4 12s9.84 12 22.4 12c11.72 0 22.94-5.5 22.4-12s-9.84-12-22.4-12z"
        />
        <path
          id="shape4"
          className="blue-bg"
          d="M1111.46 354.15c-18-13.25-59.59-24.77-76.37-19.63-6.35 1.94-8.91 9.38-6.9 15.15s7.28 10.1 12.68 13.68c14.41 9.58 72.84 35 78.33 5a10.45 10.45 0 00-1.38-8.09 29.35 29.35 0 00-6.36-6.11z"
        />
        <path
          id="shape5"
          className="blue-bg"
          d="M1163.68 215c-34.62 1.31-72.25 13.74-103.93 22.93-13.07 3.8-26.33 8.7-34.94 16.29s-10.81 18.43-1.81 23.93c6.13 3.74 15.78 4.28 24.91 4.28a342 342 0 00115.79-20.69V215z"
        />
        <path
          id="shape6"
          className="blue-bg"
          d="M970.62 100.8c.65 11.14 26 2.28 29.61-5.4 3.87-8.25.46-17.29-10.21-14.42-4 1.06-7.36 3.66-10.61 6.21-3.41 2.68-7 5.64-8.25 9.83a11.3 11.3 0 00-.54 3.78z"
        />
        <path
          id="shape7"
          className="blue-bg"
          d="M937.64 447.91c-21.38-15.57-74.47-4.57-98.09 2.45-5.33 1.58-10.64 3.42-14.59 6.28-14.53 10.56 3.4 23.18 19.56 25.71 24.23 3.8 51.77-3.83 74-9.46 9.81-2.48 21-6 23.18-12.85 1.66-5.31-.12-9.25-4.06-12.13z"
        />
        <path
          id="shape8"
          className="blue-bg"
          d="M985.43 277.43c-4.27-11.23-26.43-12.9-35.91-11.65-27.09 3.6-31.68 31-53.54 42.17-13.69 7-30.46-4.94-41.38 9.73-3.49 4.69-2.57 10.5 2.74 13.38 2.79 1.52 6 1.9 9.21 2 14.57.64 28.91-3.21 43-7.08 24.62-6.75 54.9-14.91 72.51-34.87a15 15 0 004.12-9.55 11.08 11.08 0 00-.72-4.17z"
        />
      </g>
      <g id="arrows2" className="arrows">
        <text className="bold-text" transform="translate(762.94 129.15)">
          Competencies
        </text>
        <path className="triangle full" d="M798.12 209.6l5.45-9.44h-10.9z" />
        <path className="triangle dim" d="M798.12 190.37l5.45-9.44h-10.9z" />
        <path className="triangle dimmer" d="M798.12 171.14l5.45-9.43h-10.9z" />
        <path
          className="triangle dimmest"
          d="M798.12 151.92l5.45-9.44h-10.9z"
        />
      </g>
      <g id="arrows1" className="arrows">
        <text className="bold-text" transform="translate(489.09 204.93)">
          Developing...
        </text>
        <path className="triangle full" d="M499.15 288.23l5.45-9.44h-10.9z" />
        <path className="triangle dim" d="M499.15 269l5.45-9.44h-10.9z" />
        <path className="triangle dimmer" d="M499.15 249.77l5.45-9.43h-10.9z" />
        <path
          className="triangle dimmest"
          d="M499.15 230.55l5.45-9.44h-10.9z"
        />
        <path className="triangle full" d="M541.56 288.23l5.45-9.44h-10.9z" />
        <path className="triangle dim" d="M541.56 269l5.45-9.44h-10.9z" />
        <path className="triangle dimmer" d="M541.56 249.77l5.45-9.43h-10.9z" />
        <path
          className="triangle dimmest"
          d="M541.56 230.55l5.45-9.44h-10.9z"
        />
        <path className="triangle full" d="M520.35 307.68l5.45-9.44h-10.89z" />
        <path className="triangle dim" d="M520.35 288.45l5.45-9.45h-10.89z" />
        <path
          className="triangle dimmer"
          d="M520.35 269.22l5.45-9.44h-10.89z"
        />
        <path className="triangle dimmest" d="M520.35 250l5.45-9.44h-10.89z" />
      </g>
      <g
        id="lines"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#e61515"
        strokeDasharray="1190.27 1191.69"
      >
        <path d="M1039 108.33h10.46v10.46" />
        <path
          d="M-.45 366.76s95.09-98.59 185.71-51.65c15.5 8 29.47 21.52 56.06 21.52 57 0 67.34-64 116.6-76.22 36.16-8.94 62.8 18.41 83.87 38.65 11.9 11.43 23.47 22.95 40.62 28.83 20.66 7.08 44.64 7.35 65.38.31 35.58-12.07 56.29-40 76.65-65.68 23.45-29.58 65.2-82.46 115.76-59.92 11.82 5.26 20.77 14 31.06 21.22s23.5 13.06 36.6 10.75c9.9-1.75 17.83-7.85 24.81-14 18.27-16.15 34-34.79 50.18-53 17.16-19.35 38.07-52.66 68.8-36.74 9.7 5 16.94 14.32 27.2 18.11 29.59 10.94 52.85-23.06 70.62-40.48"
          strokeWidth=".75"
        />
      </g>
      <g id="group-1" className="animate-group">
        <path className="line" d="M370.7 258.88v115.77" />
        <text className="label-text" transform="translate(367.7 396.84)">
          Relational Intelligence
        </text>
        <g id="Point-1">
          <circle className="line inner" cx="370.7" cy="258.68" r="23.68" />
          <circle className="line outer" cx="370.7" cy="258.68" r="37.57" />
          <circle className="full" cx="370.7" cy="258.68" r="5.5" />
        </g>
      </g>
      <g id="group-2" className="animate-group">
        <path className="line" d="M650.83 230.75v125.72" />
        <text className="label-text" transform="translate(646.83 376.65)">
          Conversational Intelligence
        </text>
        <g id="Point-2">
          <circle className="line inner" cx="650.95" cy="230.75" r="23.68" />
          <circle className="line outer" cx="650.95" cy="230.75" r="37.57" />
          <circle className="full" cx="650.95" cy="230.75" r="5.5" />
        </g>
      </g>
      <g id="group-3" className="animate-group">
        <path className="line" d="M723.24 197.43v98" />
        <text className="label-text" transform="translate(720.09 314.77)">
          Emotional Intelligence
        </text>
        <g id="Point-3">
          <circle className="line inner" cx="723.24" cy="197.43" r="23.68" />
          <circle className="line outer" cx="723.24" cy="197.43" r="37.57" />
          <circle className="full" cx="723.24" cy="197.43" r="5.5" />
        </g>
      </g>
      <g id="group-4" className="animate-group">
        <path className="line" d="M931.91 127.16v108.07" />
        <text className="label-text" transform="translate(928.91 254.42)">
          Social Intelligence
        </text>
        <g id="Point-4">
          <circle className="line inner" cx="931.91" cy="127.16" r="23.68" />
          <circle className="line outer" cx="931.91" cy="127.16" r="37.57" />
          <circle className="full" cx="931.91" cy="127.16" r="5.5" />
        </g>
      </g>
    </Box>
  )
}

export default AnimateTwo
