import React from "react"
import { Box } from "theme-ui"
import { Link, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import { Section } from "elements-ui"
import { SEO } from "@maker-ui/seo"

import AnimateOne from "../components/Animations/AnimateOne"
import AnimateTwo from "../components/Animations/AnimateTwo"
import PortableText from "../components/PortableText"
import { Carousel } from "../components/Carousel"
import RaveSlide from "../components/RaveSlide"

// Image Assets
import services from "../utils/serviceTiles"

export default ({ data }) => {
  const {
    _rawFirstBody,
    _rawSecondBody,
    ctaButtonLink,
    ctaButtonText,
    firstTitle,
    secondTitle,
    testimonialTitle,
    testimonialBackground,
    raves,
  } = data.sanityHomePage
  return (
    <>
      <SEO title="Momentous Meetings | Elevate Your Meetings" noTemplate />
      <Box
        sx={{
          minHeight: ["70vh", "100vh"],
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            m: ["60px 20px 30px", "100px 0 0 15%"],
            maxWidth: 860,
            p: { variant: "homeText" },
            h2: {
              fontSize: [32, 42],
              mb: 30,
            },
          }}
        >
          <h2>{firstTitle}</h2>
          <PortableText blocks={_rawFirstBody} />
        </Box>
        <AnimateOne />
        <Section
          padding="0 20px"
          maxWidth="1260px"
          sx={{
            mt: ["-80px", "-150px"],
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr 1.5fr"],
            gridGap: [30, 50],
            mb: [-40, -100],
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <Box />
          <Box
            sx={{
              p: { variant: "homeText" },
              py: 30,
              maxWidth: 700,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              a: { color: "primary", textDecoration: "underline" },
            }}
          >
            <PortableText blocks={_rawSecondBody} />
          </Box>
        </Section>
        <AnimateTwo />
      </Box>
      <Box
        as="section"
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr"],
        }}
      >
        {services.map((item) => (
          <Box
            key={item.title}
            variant="serviceTile"
            sx={{
              bg: item.bg,
              color: item.color,
              a: {
                color: item.color,
                "&:hover": { bg: item.color, color: item.bg },
              },
              ".icon-wrapper": {
                borderColor: item.color,
              },
              svg: { fill: item.color },
            }}
          >
            <div className="icon-wrapper">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
            <Link to={item.path}>Learn More</Link>
          </Box>
        ))}
      </Box>
      <Fade effect="fadeInUp">
        <Box
          sx={{
            display: "flex",
            background: `url(${testimonialBackground.asset.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 620,
            bg: "#d8d8d8",
            backgroundBlendMode: "overlay",
            textAlign: "center",
          }}
        >
          <Box
            id="featured-raves"
            sx={{
              p: "100px 20px",
              m: "auto",
              h2: { textAlign: "center", fontSize: [30, 42] },
            }}
          >
            <h2>{testimonialTitle}</h2>
            <Carousel
              data={raves}
              template={<RaveSlide />}
              transition="fade"
              autoPlay
              duration={9000}
            />
          </Box>
        </Box>
      </Fade>
    </>
  )
}

export const query = graphql`
  query {
    sanityHomePage {
      _rawFirstBody
      _rawSecondBody
      ctaButtonLink
      ctaButtonText
      firstTitle
      secondTitle
      testimonialTitle
      testimonialBackground {
        asset {
          url
        }
      }
      raves {
        raveText
        position
        company
        author
      }
    }
  }
`
