import React from "react"
import {
  FacilitationIcon,
  LeadershipIcon,
  CoachingIcon,
  RetreatsIcon,
} from "../assets/icons"

export default [
  {
    title: "Coaching",
    bg: "primary",
    color: "#fff",
    icon: <CoachingIcon />,
    path: "/coaching",
    text:
      "Team, group and individual coaching partnerships provide long lasting holistic solutions.",
  },
  {
    title: "Leadership & Training",
    bg: "#d6d6d6",
    color: "#000",
    icon: <LeadershipIcon />,
    path: "/leadership",
    fade: "left",
    text:
      "Growth mindset and great communication skills are learned – Love of learning can be cultivated and embraced.",
  },
  {
    title: "Facilitation",
    bg: "#f7f6f6",
    color: "#000",
    icon: <FacilitationIcon />,
    path: "/facilitation",
    fade: "left",
    text:
      "Acting as a catalyst for better conversations, we enable focus, balance, and idea generation.",
  },
  {
    title: "Offsites & Retreats",
    bg: "#000",
    color: "#fff",
    icon: <RetreatsIcon />,
    path: "/retreats",
    text:
      "Give your organization or team a fresh approach to foster cohesiveness, loyalty and enhanced relationships.",
  },
]
